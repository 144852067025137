:root {
    --background: #e2e1fa;
    --content: #fff;

    --secondary-color: #106eec;

    --gray: #f3f0ec;
    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-800: #29292e;
    --gray-850: #1f2729;
    --gray-900: #121214;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media (max-width: 1280px) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

@media (max-width: 601px) {
    html {
        font-size: 82%;
    }
}

@media (max-width: 480px) {
    html {
        font-size: 70%;
    }

    img {
        max-width: 90%;
        height: auto;
    }
}

body,
input,
textarea,
button {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #000;
}

section {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
}

section::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000, #ffdf00, #000);
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

section span {
    position: relative;
    display: block;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    background: #181818;
    z-index: 2;
    transition: 1.5s;
}

section span:hover {
    background: #ffdf00;
    transition: 0s;
}

section .signin {
    position: absolute;
    width: 400px;
    background: #222;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 9);
}

section .signin .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

label {
    font-size: 0.8em;
    color: #FFF;
    margin-bottom: 8px;
}

section .signin .content h2 {
    font-size: 2em;
    color: #304584;
    text-transform: uppercase;
}

section .signin .content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

section .signin .content .form .inputBox {
    position: relative;
    width: 100%;
}

section .signin .content .form .inputBox input {
    position: relative;
    width: 100%;
    background: #333;
    border: none;
    outline: none;
    padding: 10px 7.5px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 1em;
}

.signin .content .form .links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.signin .content .form .links a {
    color: #fff;
    text-decoration: none;
}

.signin .content .form .links a:nth-child(2) {
    color: #304584;
    font-weight: 600;
}

button{
    background: #304584;
    color: #fff;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.05em;
    width: 100%;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border: none;
}

button:active {
    opacity: 0.6;
}

@media (max-width: 900px) {
    section span {
        width: calc(10vw - 2px);
        height: calc(10vw - 2px);
    }
}

@media (max-width: 600px) {
    section span {
        width: calc(20vw - 2px);
        height: calc(20vw - 2px);
    }
}
